<template>
  <div class="bg-cl">
    <div id="biaoqian" style="height:70vh;width:80vh"></div>
  </div>
</template>

<script>
import * as echarts from "echarts"
import "echarts-wordcloud"
export default {
  mounted() {
    this.getBiaoqianOptins()
  },
  methods: {
    getBiaoqianOptins() {
      var biaoqian = document.getElementById("biaoqian")
      var biaoqianChart = echarts.init(biaoqian)
      let datas = [
        {
          name: "给你一份温馨",
          value: 50,
        },
        {
          name: "国华众联",
          value: 49,
        },
        {
          name: "满意",
          value: 30,
        },
        {
          name: "有意义",
          value: 28,
        },
        {
          name: "充实",
          value: 24,
        },
        {
          name: "活动丰富",
          value: 23,
        },
        {
          name: "学有所获",
          value: 22,
        },
        {
          name: "多组织户外活动",
          value: 25,
        },
        {
          name: "开心",
          value: 24,
        },
        {
          name: "安全",
          value: 29,
        },
        {
          name: "学到知识",
          value: 28,
        },
        {
          name: "放心",
          value: 27,
        },
        {
          name: "融入社会",
          value: 26,
        },
        {
          name: "自力更生",
          value: 25,
        },
      ]

      var option
      option = {
        tooltip: {
          show: true,
          position: "top",
          textStyle: {
            fontSize: 30,
          },
        },
        series: [
          {
            type: "wordCloud",
            // 网格大小，各项之间间距
            gridSize: 50,
            // 形状 circle 圆，cardioid  心， diamond 菱形，
            // triangle-forward 、triangle 三角，star五角星
            shape: "circle",
            // 字体大小范围
            sizeRange: [50, 100],
            // 文字旋转角度范围
            rotationRange: [0, 30],
            // 旋转步值
            // rotationStep: 90,
            // 自定义图形
            // maskImage: maskImage,
            left: "center",
            top: "center",
            right: null,
            bottom: null,
            // 画布宽
            width: "90%",
            // 画布高
            height: "80%",
            // 是否渲染超出画布的文字
            drawOutOfBound: false,
            textStyle: {
              normal: {
                color: function() {
                  return (
                    "rgb(" +
                    [
                      Math.round(Math.random() * 200 + 55),
                      Math.round(Math.random() * 200 + 55),
                      Math.round(Math.random() * 200 + 55),
                    ].join(",") +
                    ")"
                  )
                },
              },
              emphasis: {
                shadowBlur: 10,
                shadowColor: "#2ac",
              },
            },
            data: datas,
          },
        ],
      }
      biaoqianChart.setOption(option)
      window.addEventListener("resize", function() {
        biaoqianChart.resize() //下面可以接多个图
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.bg-cl {
  width: 100%;
  height: 100vh;
  background-color: #0d3099;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
